/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.icon-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #f3f4f6; /* Tailwind color gray-200 */
  border-radius: 9999px; /* Fully rounded corners */
}

// .mdc-text-field--filled {
//   border: 1px black solid;
//   border-radius: 9999px;
// }
.mat-mdc-text-field-wrapper {
  border-radius: 30px !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 20px !important;
}
